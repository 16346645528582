<template>
  <ValidationObserver ref="formValidation" v-slot="{ handleSubmit }">
    <b-alert :show="Object.keys(errors).length > 0" variant="danger">
      <h4 class="alert-heading">
        Виникла помилка при збереженні договору!
      </h4>
      <li v-for="(error, index) in errors" :key="index">
        <span v-for="(e, i) in error" :key="i">
          {{ e }}
        </span>
      </li>
    </b-alert>
    <b-form @submit.prevent="handleSubmit(onSubmit)">
      <ValidationProvider
          v-if="isExternalTenantAvailable"
          name="зовнішній тенант"
          rules="required|max:255"
          v-slot="{ errors, dirty, validated, valid }"
          vid="external_tenant_id"
      >
        <b-form-group label="Зовнішній тенант">
          <b-form-select
              v-model="form.external_tenant_id"
              :options="externalTenants"
              text-field="name"
              value-field="id"
              :state="getValidationState(dirty, validated, valid)"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="номер договору"
          rules="required|max:255"
          v-slot="{ errors, dirty, validated, valid }"
          vid="number"
      >
        <b-form-group label="Номер договору">
          <b-form-input
              type="text"
              v-model="form.number"
              :state="getValidationState(dirty, validated, valid)"
              maxlength="255"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="дата укладання договору"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          vid="issued_date"
      >
        <b-form-group label="Дата укладання договору">
          <b-form-input
              type="date"
              v-model="form.issued_date"
              :state="getValidationState(dirty, validated, valid)"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="контрагент"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          vid="counterpart"
      >
        <b-form-group label="Контрагент">
          <multiselect
              :value="counterparts.find(o => o.id === form.counterpart_id)"
              @input="value => (form.counterpart_id = value ? value.id : null)"
              :custom-label="nameInit"
              track-by="id"
              selectLabel="Обрати"
              :options="counterparts"
              placeholder="Вибір контрагента"
              :state="getValidationState(dirty, validated, valid)"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="тип договору"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          vid="type_id"
      >
        <b-form-group label="Тип договору">
          <b-form-select
              v-model="form.type_id"
              :options="types"
              text-field="name"
              value-field="id"
              :state="getValidationState(dirty, validated, valid)"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="код призначення платежу"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          v-if="!isOutcomingContract(type)"
          vid="payment_destination_code_id"
      >
        <b-form-group label="Код призначення платежу">
          <multiselect
              :value="
              operationDestinationCode.find(
                o => o.id === form.payment_destination_code_id
              )
            "
              @input="
              value =>
                (form.payment_destination_code_id = value ? value.id : null)
            "
              :custom-label="labelDestinationCode"
              track-by="id"
              selectLabel="Обрати"
              :options="operationDestinationCode"
              placeholder="Вибір коду призначення платежу"
              :state="getValidationState(dirty, validated, valid)"
          >
            <template
                class="payment-destination-code-label"
                v-slot:option="scope"
            >
              <img
                  v-if="scope.option.ended_at"
                  src="/images/text-help.svg"
                  alt="help"
                  style="margin-right: 10px;"
                  v-b-tooltip
                  :title="tooltipDestinationCode(scope.option)"
              />
              <template>
                {{ labelDestinationCode(scope.option) }}
              </template>
            </template>
          </multiselect>
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          name="опис коду призначення платежу"
          v-if="
          !isOutcomingContract(type) &&
            isSpecialCode(form.payment_destination_code_id)
        "
          :rules="{
          required: isSpecialCode(form.payment_destination_code_id),
          max: 255
        }"
          v-slot="{ errors, dirty, validated, valid }"
          vid="payment_destination_code_note"
      >
        <b-form-group label="Опис коду призначення платежу">
          <b-form-input
              type="text"
              v-model="form.payment_destination_note"
              :state="getValidationState(dirty, validated, valid)"
              maxlength="255"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          v-if="isOutcomingContract(type) || isMixedContract(type)"
          name="код призначення виплати"
          rules="required"
          v-slot="{ errors, dirty, validated, valid }"
          vid="payout_destination_code_id"
      >
        <b-form-group label="Код призначення виплати">
          <multiselect
              :value="
              operationDestinationCode.find(
                o => o.id === form.payout_destination_code_id
              )
            "
              @input="
              value =>
                (form.payout_destination_code_id = value ? value.id : null)
            "
              :custom-label="labelDestinationCode"
              track-by="id"
              selectLabel="Обрати"
              :options="operationDestinationCode"
              placeholder="Вибір коду призначення виплати"
              :state="getValidationState(dirty, validated, valid)"
          >
            <template
                class="payout-destination-code-label"
                v-slot:option="scope"
            >
              <img
                  v-if="scope.option.ended_at"
                  src="/images/text-help.svg"
                  alt="help"
                  style="margin-right: 10px;"
                  v-b-tooltip
                  :title="tooltipDestinationCode(scope.option)"
              />
              <template>
                {{ labelDestinationCode(scope.option) }}
              </template>
            </template>
          </multiselect>
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <ValidationProvider
          v-if="
          (isOutcomingContract(type) || isMixedContract(type)) &&
            isSpecialCode(form.payout_destination_code_id)
        "
          name="опис коду призначення виплати"
          :rules="{
          required: isSpecialCode(form.payout_destination_code_id),
          max: 255
        }"
          v-slot="{ errors, dirty, validated, valid }"
          vid="payment_destination_code_note"
      >
        <b-form-group label="Опис коду призначення виплати">
          <b-form-input
              type="text"
              v-model="form.payout_destination_note"
              :state="getValidationState(dirty, validated, valid)"
              maxlength="255"
          />
          <b-form-invalid-feedback
              :state="errors.length === 0"
              v-for="(error, index) in errors"
              v-bind:key="index"
          >
            {{ error }}
          </b-form-invalid-feedback>
        </b-form-group>
      </ValidationProvider>
      <div slot="footer">
        <b-button type="submit" size="sm" variant="success">
          <i class="fa fa-dot-circle-o"></i> Додати
        </b-button>
      </div>
    </b-form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import typeMixin from "../mixins/type";

export default {
  name: "contract-form",
  mixins: [typeMixin],
  components: {
    Multiselect
  },
  data() {
    return {
      errors: {},
      form: {
        counterpart_id: null,
        issued_date: null,
        number: null,
        external_tenant_id: null,
        type_id: null,
        payment_destination_code_id: null,
        payout_destination_code_id: null,
        payment_destination_note: null,
        payout_destination_note: null
      }
    };
  },
  computed: {
    ...mapGetters({
      counterparts: "contracts/getAllCounterparts",
      types: "dictionary/allContractTypes",
      externalTenants: "dictionary/allContractExternalTenants",
      operationDestinationCode: "dictionary/allOperationDestinationCode",
      me: "auth/me"
    }),
    type: function() {
      const foundType = this.types.find(item => item.id === this.form.type_id);
      if (foundType === -1) return null;
      return foundType;
    },
    isExternalTenantAvailable: function () {
      const tenantAliases = ['aifintech', 'platizhnyytsent'];
      return tenantAliases.includes(this.me?.tenant?.alias);
    }
  },
  methods: {
    nameInit({ name, financial_model, code }) {
      return `${name} — [${financial_model.name}] - [${code}]`;
    },
    onSubmit() {
      let form = {};
      Object.entries(this.form).forEach(([key, value]) => {
        if (value !== "") form[key] = value;
      });
      this.errors = {};

      this.$store
          .dispatch("contracts/add", form)
          .then(() => {
            this.$root.$emit("bv::hide::modal", "contractForm");
            this.$snotify.success("Збережено новий договір");
          })
          .catch(({ response }) => {
            if (
                response.status !== 406 ||
                !response.data ||
                !response.data.description
            ) {
              this.$root.$emit("bv::hide::modal", "contractForm");
              this.$snotify.error(
                  "Виникла помилка при збереженні нового договору"
              );
              return;
            }

            window.scrollTo(0, 0);
            this.errors = {};

            for (let variable in response.data.description) {
              this.errors[variable] = response.data.description[variable];
            }

            this.$refs.formValidation.setErrors(this.errors);
            this.$snotify.error("Не збережено");
          });
    },
    getValidationState(dirty, validated, valid = null) {
      return dirty || validated ? valid : null;
    },
    labelDestinationCode({ name, value }) {
      return `(${value}) ${name}`;
    },
    tooltipDestinationCode($code) {
      return "Активний по " + $code.ended_at;
    }
  }
};
</script>
